.page {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: transform 0.2s ease;
}

.home-page {
  background-color: #f0f8ff; /* Light blue background for Home page */
}

.new-page {
  background-color: #faebd7; /* Light yellow background for New Page */
}

.slide-up {
  transform: translateY(-100%);
}
